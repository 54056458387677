exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-resources-blog-tsx": () => import("./../../../src/pages/resources/blog.tsx" /* webpackChunkName: "component---src-pages-resources-blog-tsx" */),
  "component---src-pages-resources-index-tsx": () => import("./../../../src/pages/resources/index.tsx" /* webpackChunkName: "component---src-pages-resources-index-tsx" */),
  "component---src-pages-resources-video-instructions-tsx": () => import("./../../../src/pages/resources/video-instructions.tsx" /* webpackChunkName: "component---src-pages-resources-video-instructions-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-cards-cards-docu-view-cards-docu-view-mdx": () => import("./../../../src/templates/documentation-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/documentation/cards/cards-docu-view/cards-docu-view.mdx" /* webpackChunkName: "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-cards-cards-docu-view-cards-docu-view-mdx" */),
  "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-cards-cards-functions-cards-functions-mdx": () => import("./../../../src/templates/documentation-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/documentation/cards/cards-functions/cards-functions.mdx" /* webpackChunkName: "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-cards-cards-functions-cards-functions-mdx" */),
  "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-cards-cards-lounge-cards-lounge-mdx": () => import("./../../../src/templates/documentation-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/documentation/cards/cards-lounge/cards-lounge.mdx" /* webpackChunkName: "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-cards-cards-lounge-cards-lounge-mdx" */),
  "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-cards-cards-sidebar-cards-sidebar-mdx": () => import("./../../../src/templates/documentation-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/documentation/cards/cards-sidebar/cards-sidebar.mdx" /* webpackChunkName: "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-cards-cards-sidebar-cards-sidebar-mdx" */),
  "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-cards-cards-view-cards-view-mdx": () => import("./../../../src/templates/documentation-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/documentation/cards/cards-view/cards-view.mdx" /* webpackChunkName: "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-cards-cards-view-cards-view-mdx" */),
  "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-cards-create-card-create-card-mdx": () => import("./../../../src/templates/documentation-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/documentation/cards/create-card/create-card.mdx" /* webpackChunkName: "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-cards-create-card-create-card-mdx" */),
  "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-document-viewer-document-viewer-mdx": () => import("./../../../src/templates/documentation-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/documentation/document-viewer/document-viewer.mdx" /* webpackChunkName: "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-document-viewer-document-viewer-mdx" */),
  "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-getting-started-frequently-asked-questions-frequently-asked-questions-mdx": () => import("./../../../src/templates/documentation-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/documentation/getting-started/frequently-asked-questions/frequently-asked-questions.mdx" /* webpackChunkName: "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-getting-started-frequently-asked-questions-frequently-asked-questions-mdx" */),
  "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-getting-started-welcome-to-elumity-welcome-to-elumity-mdx": () => import("./../../../src/templates/documentation-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/documentation/getting-started/welcome-to-elumity/welcome-to-elumity.mdx" /* webpackChunkName: "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-getting-started-welcome-to-elumity-welcome-to-elumity-mdx" */),
  "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-library-chapter-tree-chapter-tree-mdx": () => import("./../../../src/templates/documentation-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/documentation/library/chapter-tree/chapter-tree.mdx" /* webpackChunkName: "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-library-chapter-tree-chapter-tree-mdx" */),
  "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-library-library-view-library-view-mdx": () => import("./../../../src/templates/documentation-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/documentation/library/library-view/library-view.mdx" /* webpackChunkName: "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-library-library-view-library-view-mdx" */),
  "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-library-top-navigation-bar-top-navigation-bar-mdx": () => import("./../../../src/templates/documentation-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/documentation/library/top-navigation-bar/top-navigation-bar.mdx" /* webpackChunkName: "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-library-top-navigation-bar-top-navigation-bar-mdx" */),
  "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-quiz-add-custom-questions-add-custom-questions-mdx": () => import("./../../../src/templates/documentation-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/documentation/quiz/add-custom-questions/add-custom-questions.mdx" /* webpackChunkName: "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-quiz-add-custom-questions-add-custom-questions-mdx" */),
  "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-quiz-create-quiz-create-quiz-mdx": () => import("./../../../src/templates/documentation-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/documentation/quiz/create-quiz/create-quiz.mdx" /* webpackChunkName: "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-quiz-create-quiz-create-quiz-mdx" */),
  "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-settings-backup-library-backup-library-mdx": () => import("./../../../src/templates/documentation-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/documentation/settings/backup-library/backup-library.mdx" /* webpackChunkName: "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-settings-backup-library-backup-library-mdx" */),
  "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-settings-change-display-name-change-display-name-mdx": () => import("./../../../src/templates/documentation-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/documentation/settings/change-display-name/change-display-name.mdx" /* webpackChunkName: "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-settings-change-display-name-change-display-name-mdx" */),
  "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-settings-change-email-change-email-mdx": () => import("./../../../src/templates/documentation-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/documentation/settings/change-email/change-email.mdx" /* webpackChunkName: "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-settings-change-email-change-email-mdx" */),
  "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-settings-change-info-change-info-mdx": () => import("./../../../src/templates/documentation-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/documentation/settings/change-info/change-info.mdx" /* webpackChunkName: "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-settings-change-info-change-info-mdx" */),
  "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-settings-change-password-change-password-mdx": () => import("./../../../src/templates/documentation-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/documentation/settings/change-password/change-password.mdx" /* webpackChunkName: "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-settings-change-password-change-password-mdx" */),
  "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-settings-change-profile-photo-change-profile-photo-mdx": () => import("./../../../src/templates/documentation-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/documentation/settings/change-profile-photo/change-profile-photo.mdx" /* webpackChunkName: "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-settings-change-profile-photo-change-profile-photo-mdx" */),
  "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-settings-delete-account-delete-account-mdx": () => import("./../../../src/templates/documentation-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/documentation/settings/delete-account/delete-account.mdx" /* webpackChunkName: "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-settings-delete-account-delete-account-mdx" */),
  "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-write-write-mdx": () => import("./../../../src/templates/documentation-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/documentation/write/write.mdx" /* webpackChunkName: "component---src-templates-documentation-entry-tsx-content-file-path-resources-documentation-write-write-mdx" */),
  "component---src-templates-documentation-overview-tsx": () => import("./../../../src/templates/documentation-overview.tsx" /* webpackChunkName: "component---src-templates-documentation-overview-tsx" */)
}

